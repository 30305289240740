.App {
  width: 100vw;
  height: 100vh;
  margin: 10;
  background-color: transparent;
  /*font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  background-color: rgba(0,0,0,.4);
}

.App-content {
  position: absolute;
  top:100px;
  left:10px;
  width: 90vw;
  color:white;
}

.App-textbox {
  padding: 8px 8px 8px 8px;
  background-color: rgba(0,0,0,.6);
  width: 80%;
  color:white;
}

.App-contentbox {
  padding: 8px 8px 8px 8px;
  background-color: rgba(0,0,0,.8);
  margin:10px;
  width: 80%;
  color:white;
  border-width: thin;
  border: solid rgb(37, 36, 46);
}

.App-header {
  position: absolute;
  top:0;
  font-size: calc(25px + 2vmin);
  color: white;
}

.App-title {
  font-size: 16px;
  font-weight: bolder;
  color: white;
  background-color: rgba(0,0,0,.8);
}

.App-tracktitle {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  color: white;
  background-color: rgba(0,0,0,.8);
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
